import React from "react";
import ProductTemplate from "./product";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Ja disponible el curs més complet per preparar la següent convocatòria de Mossos
                    d'Esquadra que està programada pel tercer trimestre d'aquest any 2024.
                </p>
                <p>
                    El millor curs que et donarà la base que necessites per preparar la primera
                    prova amb garanties
                </p>
                <p>
                    A iOpos tenim el nostre mètode d'estudi i trobaràs tots els continguts des del
                    moment d'accés disponibles, en cap moment et traiem cap contingut, tot el
                    contrari cada setmana hi ha noves activitats.
                </p>
                <p>
                    Ara encara més renovat amb més material i exercicis on els nostres professors us
                    avaluaran constantment.
                </p>
                <p>Un curs que s'adapta a tu i els teus horaris.</p>
                <p>
                    Al curs trobaràs vídeos explicatius de tot el temari específic, vídeos
                    d'actualitat i de psicotècnics aptitudinals.
                </p>
                <p>
                    Aviat es faran classes en directe del temari específic on podreu interactuar amb
                    la nostra professora, on les classes quedaran gravades per visionar-les tantes
                    vegades com vulgueu.
                </p>
                <p>Un curs amb actualitzacions setmanals!</p>
                <p>
                    <strong>CONTINGUT DEL CURS:</strong>
                </p>
                <ul>
                    <li>- Temari</li>
                    <li>- Totes les notícies d'actualitat</li>
                    <li>- Psicotècnics</li>
                    <li>- Vídeos explicatius</li>
                    <li>- Material didàctic</li>
                    <li>- Resums</li>
                    <li>- Exercicis</li>
                    <li>- Test</li>
                    <li>- Informació rellevant</li>
                    <li>- Tutories personalitzades</li>
                </ul>
                <p>El curs està estructurat en tres apartats:</p>
                <p>
                    <strong>ÀMBIT A</strong>
                </p>
                <ul>
                    <li>1. Història de Catalunya (part I)</li>
                    <li>2. Història de Catalunya (part II)</li>
                    <li>3. Història de la Policia a Catalunya</li>
                    <li>4. Àmbit sociolingüístic</li>
                    <li>5. Marc geogràfic de Catalunya</li>
                    <li>6. Entorn social a Catalunya</li>
                    <li>7. Les tecnologies de la informació</li>
                </ul>
                <p>
                    <strong>ÀMBIT B</strong>
                </p>
                <ul>
                    <li>1. L'Estatut d'Autonomia de Catalunya</li>
                    <li>2. Les institucions polítiques de Catalunya</li>
                    <li>3. L'ordenament jurídic de l'Estat</li>
                    <li>4. Els drets humans i els drets constitucionals</li>
                    <li>5. Les institucions polítiques de l'Estat</li>
                    <li>6. Els òrgans jurisdiccionals. Poder Judicial i Tribunal Constitucional</li>
                    <li>7. L'organització territorial de l'Estat</li>
                    <li>8. La Unió Europea</li>
                </ul>
                <p>
                    <strong>ÀMBIT C</strong>
                </p>
                <ul>
                    <li>
                        1. Les competències de la Generalitat de Catalunya en matèria de seguretat
                    </li>
                    <li>2. El Departament d'Interior</li>
                    <li>3. La coordinació policial</li>
                    <li>4. El marc legal de la seguretat</li>
                    <li>5. El codi deontològic policial</li>
                </ul>
                <p>
                    <strong>NOTÍCIES D'ACTUALITAT</strong>
                </p>
                <ul>
                    <li>- Totes les notícies més rellevants </li>
                    <li>- Exercicis tests per posar-vos a prova</li>
                </ul>
                <p>
                    <strong>PSICOTÈCNICS:</strong>
                </p>
                <ul>
                    <li>- Vídeos explicatius de tots els raonaments.</li>
                    <li>- Trucs i pràctica de psicotècnics</li>
                    <li>- Exercicis en forma de test</li>
                </ul>

                <p>
                    <strong>El curs estarà obert fins al dia de l'examen 2024.</strong>
                </p>

                <p>
                    Preu: 489,99 euros tot el curs, amb possibilitat de pagar en tres vegades a
                    través de PayPal sense interessos
                </p>

                <p>
                    Un cop matriculat et posarem en un grup de treball, amb reptes setmanals i
                    Kahoot.
                </p>

                <p>No deixis que t'ho expliquin!</p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="No deixis passar l'oportunitat de dominar la prova dels Mossos d'Esquadra amb el nostre curs intensiu. Amb materials actualitzats, classes en directe i suport constant, estaràs totalment preparat per afrontar qualsevol repte. Inscriu-te ara i assegura't un avantatge competitiu!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
